import { extend, useLoader, useThree } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { GroundedSkybox } from 'three/examples/jsm/Addons.js'
import { useEffect } from 'react'
import * as THREE from 'three'

extend({ GroundedSkybox })

export default function SceneEnvironment({ 
    texture, 
    envHeight = 2.4,
    envRadius = 7,
    envScale = 100,
    heightOffset = 35
}) {
    const loadedTexture = useLoader(TextureLoader, texture)

    const { scene } = useThree()

    useEffect(() => {
        loadedTexture.mapping = THREE.EquirectangularReflectionMapping
        loadedTexture.colorSpace = THREE.SRGBColorSpace
        scene.environment = loadedTexture
        if (scene.fog) {
            scene.fog.near = 0.1
            scene.fog.far = 0
        }
    }, [scene])

    useEffect(() => {
        return () => {
            if (loadedTexture) {
                loadedTexture.dispose()
            }
        }
    })

    return (
        <group scale={ envScale } position-y={ heightOffset }>
            <groundedSkybox
                args={ [loadedTexture, envHeight, envRadius, 40] }
            />  
        </group>
    )
}
