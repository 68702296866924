import '../../styles/LoadingOverlay.css'
import { useDispatch } from 'react-redux'
import { changeCameraModeToOrbit } from '../slices/cameraSlice'
import { useState } from 'react'

export default function LoadingOverlay() {
    const [ inExperience, setIsExperience ] = useState(false)
    const dispatch = useDispatch()

    const startExperience = function() {
        dispatch(changeCameraModeToOrbit())
        setIsExperience(true)
    }

    return (
        <>
        { !inExperience && 
            <div id="loading-overlay">
                <button onClick={ startExperience }>Start</button>
            </div>
        }
        </>
    )
}
