class AudioService {
    constructor() {
        if (typeof window !== 'undefined') {
            this.audioElement = new Audio('/audio/xmas-carol.mp3');
            this.audioElement.loop = true;
        } else {
            this.audioElement = null;
        }
    }

    playAudio() {
        this.audioElement.play();
    }

    stopAudio() {
        this.audioElement.pause();
    }

    isPlaying() {
        return !this.audioElement.paused;
    }

    setMuted(isMuted) {
        this.audioElement.muted = isMuted;
    }
}

const audioService = new AudioService();
export default audioService;