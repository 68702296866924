import { button, Leva, useControls } from 'leva'
import { useDispatch } from 'react-redux'
import { hideSanta } from '../slices/santaFigureSlice'
import { setCzech, setGerman } from '../slices/languageSlice'
import { setHasWon } from '../slices/collectiblesSlice'
import { lockCamera, unlockCamera, changeCameraTargetToSanta, changeCameraTargetToSide } from '../slices/cameraSlice'
import { setIsGiver, setIsLoser, setIsReceiver, setIsWinner } from '../slices/userSlice'

export default function Debug() {
    const dispatch = useDispatch()

    const onHasWon = function() {
        dispatch(setHasWon())
    }

    const onHideSanta = function() {
        dispatch(hideSanta())
    }

    const onSetCzech = function() {
        dispatch(setCzech())
    }

    const onSetGerman = function() {
        dispatch(setGerman())
    }

    const onLockCamera = function() {
        dispatch(lockCamera())
    }

    const onUnlockCamera = function() {
        dispatch(unlockCamera())
    }

    const onSetCameraTargetToSanta = function() {
        dispatch(changeCameraTargetToSanta())
    }

    const onSetCameraTargetToSide = function() {
        dispatch(changeCameraTargetToSide())
    }

    const onSetIsGiver = function() {
        dispatch(setIsGiver())
    }

    const onSetIsReceiver = function() {
        dispatch(setIsReceiver())
    }

    const onSetIsWinner = function() {
        dispatch(setIsWinner())
    }

    const onSetIsLoser = function() {
        dispatch(setIsLoser())
    }
    
    const { perm } = useControls('debug', {
        setHasWon: button(onHasWon),
        hideSanta: button(onHideSanta),
        setCzech: button(onSetCzech),
        setGerman: button(onSetGerman),
        lockCamera: button(onLockCamera),
        unlockCamera: button(onUnlockCamera),
        setCameraTargetToSanta: button(onSetCameraTargetToSanta),
        setCameraTargetToSide: button(onSetCameraTargetToSide),
        setIsGiver: button(onSetIsGiver),
        setIsReceiver: button(onSetIsReceiver),
        setIsWinner: button(onSetIsWinner),
        setIsLoser: button(onSetIsLoser)
    })

    return (
        <Leva hidden/>
    )
}
