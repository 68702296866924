import { DeviceOrientationControls, OrbitControls } from '@react-three/drei'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { useControls } from 'leva'
import { useSelector } from 'react-redux'
import * as Constants from '../Constants'

export default function Camera({ 
    horizontalAngle = 0,
    verticalAngle = 0, 
    elevation = 2,
}) {
    const cameraMode = useSelector(state => state.camera.mode)
    const cameraLocked = useSelector(state => state.camera.locked)
    const cameraTarget = useSelector(state => state.camera.target)
    const [interpolationTarget, setInterpolationTarget] = useState(null)
    const [fovTarget, setFovTarget] = useState(null)
    const controls = useRef()
    const { get } = useThree()

    const { toneMapping, fov } = useControls('camera', {
        toneMapping: {
            value: 0,
            options: [
                0, 1, 2, 3, 4, 5, 7
            ]
        },

        fov: {
            value: 55
        }
    })

    const isMobile = useMemo(() => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    })

    const setCameraView = function(_horizontalAngle, _verticalAngle, _fov) {
        const target = new THREE.Vector3(
            -Math.sin(_horizontalAngle * 0.01745329251) * .001, 
            elevation - _verticalAngle, 
            Math.cos(_horizontalAngle * 0.01745329251) * .001
        )

        setInterpolationTarget(target)
        setFovTarget(_fov)

        // new TWEEN.Tween(controls.current.target)
        //     .to(
        //         {
        //             x: target.x,
        //             y: target.y,
        //             z: target.z
        //         }, 
        //         1000
        //     )
        //     .easing(TWEEN.Easing.Cubic.Out)
        //     .start()
            
        // new TWEEN.Tween(controls.current.object)
        //     .to(
        //         { fov: _fov }, 
        //         1000
        //     )
        //     .easing(TWEEN.Easing.Cubic.Out)
        //     .start()
    }

    useEffect(() => {
        get().gl.toneMapping = toneMapping
    }, [toneMapping])

    useEffect(() => {
        // https://discourse.threejs.org/t/orbitcontrols-overwrites-camera-rotation/17182/7
        get().controls.enabled = false
        get().controls.reset()
        const target = new THREE.Vector3(
            -Math.sin(horizontalAngle * 0.01745329251) * .001, 
            elevation - verticalAngle, 
            Math.cos(horizontalAngle * 0.01745329251) * .001
        )
        get().controls.target.copy(target)
        get().camera.position.set(0, elevation, 0)
        get().camera.fov = fov
        get().camera.lookAt(target)
        get().controls.enabled = true
        get().controls.update()
        get().camera.updateProjectionMatrix()
    }, [horizontalAngle, verticalAngle, elevation, cameraMode])

    useEffect(() => {
        get().controls.saveState()
        get().controls.reset()
        get().camera.fov = fov
        get().camera.updateWorldMatrix()
        get().controls.update()
    }, [fov])

    useEffect(() => {
        if (cameraTarget != Constants.CameraTargets.None) {
            const settings = Constants.CameraTargetSettings[cameraTarget]
            setCameraView(settings.horizontal, settings.vertical, settings.fov)
        }
    }, [cameraTarget])

    useFrame((state, delta) => {
        if (fovTarget != null) {
            get().camera.fov = Constants.Lerp(get().camera.fov, fovTarget, 3 * delta)
            if (Math.abs(fovTarget - get().camera.fov) < .1) {
                setFovTarget(null)
            }
        }

        if (interpolationTarget != null) {
            get().controls.target.lerp(interpolationTarget, 3 * delta)
            get().camera.updateProjectionMatrix()
            if(interpolationTarget.distanceTo(get().controls.target) < .00001) {
                setInterpolationTarget(null)
            }
        }
    })

    return (
        <>
            { 
                isMobile && cameraMode == Constants.CameraModes.Gyro 
                ? <DeviceOrientationControls makeDefault />
                : <OrbitControls    
                    ref={ controls }
                    enablePan={ false } 
                    enableRotate={ !cameraLocked }
                    maxDistance={ .001 } 
                    makeDefault 
                    autoRotate={ cameraMode == Constants.CameraModes.Rotating }
                    enableDamping={ cameraMode != Constants.CameraModes.Rotating }
                    autoRotateSpeed={ .5 }
                />
            }
        </>
    )
}
