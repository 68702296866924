import { useFrame, useLoader } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { setShowOverlay, setAudioSuspended } from '../../../../../store/slices/dataMessageSlice';


export default function SantaFigure() {
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.santaFigure.visible);
  const isPlaying = useSelector((state) => state.santaFigure.isPlaying);
  const dataSound = useSelector((state) => state.dataMessage.sound);
  const dataPhonems = useSelector((state) => state.dataMessage.phonems);
  const isSupportedBrowser = useSelector((state) => state.dataMessage.isSupportedBrowser);

  const santa = useRef();
  const audioRef = useRef(null); // Ref to store the THREE.Audio instance
  const [isModelLoaded, setModelLoaded] = useState(false);
  const [isAudioReady, setAudioReady] = useState(false);
  const [isReady, setReady] = useState(false);
  const [audioRefIsReady, setAudioRefIsReady] = useState(false);

  // Load the GLTF model
  const santaModel = useMemo(() => {
    const fullPath = `/models/santa.glb`;
    const gltf = useLoader(GLTFLoader, fullPath, (loader) => {
        loader.manager.onLoad = () => {
          console.log("Santa model fully loaded!");
        };
      });
    window.scene = gltf.scene;
    return gltf.scene.clone(true);
  }, []);

  // Check if the model is loaded
  useEffect(() => {
    if (santaModel) {
      console.log('Model ready');
      setModelLoaded(true);
    }
  }, [santaModel]);

  // Fetch and decode audio data, then set audioRef
  useEffect(() => {
    const loadAndSetAudio = async () => {
      if (dataSound) {
        try {
          const response = await fetch(dataSound);
          const audioData = await response.arrayBuffer();
          
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          const audioBuffer = await audioContext.decodeAudioData(audioData);

          const listener = new THREE.AudioListener();
          santa.current.add(listener);

          const sound = new THREE.Audio(listener);
          sound.setBuffer(audioBuffer);
          sound.setLoop(false);
          sound.setVolume(0.5);

          audioRef.current = sound; // Assign the THREE.Audio instance to audioRef
          setAudioRefIsReady(true);
          setAudioReady(true);
        } catch (error) {
          console.error("Error decoding audio data:", error);
        }
      }
    };
    loadAndSetAudio();
  }, [dataSound]);

  // Combine loading states
  useEffect(() => {
    if (isSupportedBrowser) {
      setAudioReady(true);
    }
    if (isModelLoaded && isAudioReady && dataPhonems && isSupportedBrowser) {
      setReady(true);
    }
  }, [isModelLoaded, isAudioReady, dataPhonems, isSupportedBrowser]);

  // Synchronize and play animation
  useEffect(() => {
    if (isReady && isPlaying && audioRef.current) {
      const audio = audioRef.current;
      let phonemeInterval;
      let audioStartTime = 0;
      let currentPhonemeIndex = 0;
      const phonemeDelay = 0.05; // 50 milliseconds delay

      const phonemeToMorphTarget = {
        eye: 'Close_left_eye',
        smile: 'Smile',
        u: 'Phonem_U',
        a: 'Phonem_A',
        m: 'Phonem_M_P_B',
        b: 'Phonem_M_P_B',
        p: 'Phonem_M_P_B',
        o: 'Phonem_O',
        l: 'Phonem_L',
        e: 'Phoenm_E',
        t: 'Phonem_T_D_S_Z_N_L',
        d: 'Phonem_T_D_S_Z_N_L',
        s: 'Phonem_T_D_S_Z_N_L',
        z: 'Phonem_T_D_S_Z_N_L',
        n: 'Phonem_T_D_S_Z_N_L',
        i: 'Phonem_I',
        k: 'Phonem_K_G_X',
        g: 'Phonem_K_G_X',
        x: 'Phonem_K_G_X',
      };

      const startAnimation = (phonemeData, startIndex) => {
        const mesh = santaModel.getObjectByName('Santa_Reloaded001');

        const animatePhonemes = () => {
          const currentTime = audio.context.currentTime * 1000;
          const currentPhoneme = phonemeData.find((phoneme, index) => {
            if (
              index >= startIndex &&
              currentTime >= (phoneme.start_offset - phonemeDelay) * 1000 &&
              currentTime <= phoneme.end_offset * 1000
            ) {
              currentPhonemeIndex = index;
              return true;
            }
            return false;
          });
        };
        phonemeInterval = setInterval(animatePhonemes, 100);

        phonemeData.slice(startIndex).forEach((phoneme, index) => {
          const { phoneme: name, start_offset, end_offset } = phoneme;
          const startTime = start_offset * 1000;
          const endTime = end_offset * 1000;

          const startAnimation = () => {
            new TWEEN.Tween(mesh.morphTargetInfluences).to(mesh.morphTargetInfluences.map(() => 0), 100).start();
            const morphTargetName = phonemeToMorphTarget[name];
            if (morphTargetName !== undefined) {
              const morphIndex = mesh.morphTargetDictionary[morphTargetName];
              if (morphIndex !== undefined) {
                new TWEEN.Tween(mesh.morphTargetInfluences).to({ [morphIndex]: 1 }, 100).start();
              }
            }
          };

          const endAnimation = () => {
            const morphTargetName = phonemeToMorphTarget[name];
            if (morphTargetName !== undefined) {
              const morphIndex = mesh.morphTargetDictionary[morphTargetName];
              if (morphIndex !== undefined) {
                new TWEEN.Tween(mesh.morphTargetInfluences).to({ [morphIndex]: 0 }, 100).start();
              }
            }
          };

          setTimeout(startAnimation, startTime - audioStartTime * 1000);
          setTimeout(endAnimation, endTime - audioStartTime * 1000);

          if (index === phonemeData.length - 1) {
            setTimeout(() => {
              dispatch(setShowOverlay(true));
          }, endTime + 1500);
            setTimeout(() => {
              currentPhonemeIndex = 0;
              clearInterval(phonemeInterval);
            }, endTime - audioStartTime * 1000);
          }
        });
      };

      if( !isSupportedBrowser ) {
          const audioContext = audio.context;
          if (audioContext.state === 'suspended') {
            dispatch(setAudioSuspended(true));
        
            const resumeAudio = () => {
                window.removeEventListener('touchend', resumeAudio);
                window.removeEventListener('click', resumeAudio);
        
                // Resume AudioContext and then play audio
                audioContext.resume().then(() => {
                    console.log('AudioContext resumed successfully.');
                    dispatch(setAudioSuspended(false));
                    audio.stop();
                    audio.play();
                    // audio.play().then(() => {
                    //     console.log('Audio playback started successfully.');
                    //     startAnimation(dataPhonems, currentPhonemeIndex);
                    // }).catch(err => {
                    //     console.error('Failed to play audio:', err);
                    // });
                }).catch(err => {
                    console.error('Failed to resume AudioContext:', err);
                });
            };
        
            window.addEventListener('touchend', resumeAudio);
            window.addEventListener('click', resumeAudio);
        }
         else {
            audio.play(); // Play directly if AudioContext is not suspended
            startAnimation(dataPhonems, currentPhonemeIndex);
          }
        } 
      
      else {
        audio.play(); // For other browsers (non-iOS/Safari)
        startAnimation(dataPhonems, currentPhonemeIndex);
      }
 


      return () => {
        clearInterval(phonemeInterval);
        audio.stop();
      };
    }

    const animate = () => {
      requestAnimationFrame(animate);
      TWEEN.update(); // Ensure tweens update
    };
    animate();
  }, [isReady, isPlaying, audioRefIsReady]);

  useFrame((_, delta) => {
    if (!visible) {
      santa.current.scale.lerp(new THREE.Vector3(0, 0, 0), Math.LN2 * delta * 3);
      santa.current.rotation.y = THREE.MathUtils.lerp(santa.current.rotation.y, -Math.PI * 2, delta);
    }
  });

  return (
          <primitive 
            object={ santaModel } 
            scale={ .6 }
            ref={ santa }
            position={ [-3, -1, -4] }
            rotation-y={ Math.PI / 5 }
        />
  );
}
