import { Canvas } from '@react-three/fiber'
import { useControls } from 'leva'
import { Perf } from 'r3f-perf'

export default function SceneCanvas({ children, startPosition }) {
    const { perfVisible } = useControls('debug', {
        perfVisible: false
    })

    return (
        <Canvas 
            camera={{ 
                position: startPosition, fov: 60
            }} 
            // shadows
        >
            { perfVisible && <Perf position="top-left" /> }
            { children }
        </Canvas>
    )
}
