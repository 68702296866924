export default function WrongGiftPromptView({ labelOpacity, lang }) {
    return (
        <>
            {/* <div className={`wrong-gift-label ${labelOpacity && 'animate'} `} style={{opacity: labelOpacity}}>Hledej&nbsp;dál!</div> */}
            { lang == 'sk' && <div style={{opacity: labelOpacity}}>Hľadajte&nbsp;ďalej!</div> }
            { lang == 'hu' && <div style={{opacity: labelOpacity}}>Nem&nbsp;nyert!</div> }
            { lang == 'cs' && <div style={{opacity: labelOpacity}}>Hledejte&nbsp;dál!</div> }
            
        </>
    )
}
