import { useDispatch, useSelector } from 'react-redux'
import { incrementCollectedItems } from '../slices/collectiblesSlice'
import { useMemo, useRef, useState } from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import * as THREE from 'three'
import * as Constants from '../Constants'
import { GLTFLoader } from 'three/examples/jsm/Addons.js'
import { Html } from '@react-three/drei'
import WrongGiftPromptView from '../views/WrongGiftPromptView'

export default function CollectibleItem({ position, rotation, lang }) {
    const cameraMode = useSelector(state => state.camera.mode)
    const hasWon = useSelector(state => state.collectibles.hasWon)
    const isWinner = useSelector(state => state.user.isWinner)
    const collectedCount = useSelector(state => state.collectibles.collectedCount)
    const dispatch = useDispatch()

    const [ itemGetSound ] = useState(() => new Audio('../../audio/item-get.mp3'))
    const [ labelOpacity, setLabelOpacity ] = useState(0)

    const [ isCollected, setIsCollected ] = useState(false)
    const mesh = useRef()

    const giftModel = useMemo(() => {
        const fullPath = `/models/gift.glb`
        const gltf = useLoader(GLTFLoader, fullPath)
        return gltf.scene.clone(true)
    }, [])

    const collect = function(e) {
        e.stopPropagation();
        if (cameraMode == Constants.CameraModes.Rotating
            || hasWon
            || isCollected
        ) return
        
        setIsCollected(true)

        if (!isWinner || collectedCount < Constants.CollectiblesToWin) {
            setLabelOpacity(0.01)
        }

        dispatch(incrementCollectedItems())
        
        itemGetSound.currentTime = 0
        itemGetSound.play()
    }

    useFrame((_, delta) => {
        if (isCollected) {
            mesh.current.scale.lerp(new THREE.Vector3(0, 0, 0), 2.0 * delta)
            mesh.current.rotation.y = THREE.MathUtils.lerp(mesh.current.rotation.y, -Math.PI * 2, delta)
            if (labelOpacity != 0) {
                setLabelOpacity(value => value > 5 ? 0 : value + delta)
            }
        }
    })

    const toggleHover = function(on) {
        if (hasWon || cameraMode == Constants.CameraModes.Rotating) return

        if (on) {
            document.getElementById('root').classList.add('force-pointer')
        } else {
            document.getElementById('root').classList.remove('force-pointer')
        }
    }

    return (
        <primitive 
            object={ giftModel } 
            scale={ .1 }
            position={ position } 
            rotation-y={ rotation } 
            onClick={(e) => collect(e)}
            onPointerEnter={() => toggleHover(true)}
            onPointerLeave={() => toggleHover(false)}
            ref={ mesh }
            visible={ !hasWon }
        >
            <group position-y={ 5 }>
                <Html center>
                    <WrongGiftPromptView lang={lang} labelOpacity={ labelOpacity } />
                </Html>
            </group>
        </primitive>
    )
}
